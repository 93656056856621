<template>
  <v-card flat>
    <v-card-title>Ubicación del curso</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="8" lg="4">
          <v-autocomplete
            v-model="formData.localizationId"
            :items="localizations"
            :loading="loadingLocalizations"
            label="Localización"
            item-text="title"
            item-value="const"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="formData.city"
            label="Población"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="formData.postalCode"
            label="Código postal"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12" sm="8" lg="12">
          <v-text-field
            v-model="formData.address"
            label="Dirección"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: { type: Object, required: true },
  },
  data: () => ({
    localizations: [],
    loadingLocalizations: null,
    startDateActivePicker: null,
    startDateMenu: null,
    currentService: null,
  }),
  async mounted() {
    this.currentService = this.$store.state.currentService;
    await this.getLocalizations();
  },
  methods: {
    async getLocalizations() {
      try {
        this.loadingLocalizations = true;
        const response = await this.currentService.getLocalizations();
        this.localizations = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar las localizaciones | " + e);
      } finally {
        this.loadingLocalizations = false;
      }
    },
  },
};
</script>

<style scoped></style>
